import Vue from 'vue'
import Router from 'vue-router'

import Home from '@/pages/Home'
import Policy from '@/pages/Policy'
import Introduction from '@/pages/Introduction'
import Information from '@/pages/Information'
import Detail from '@/pages/Detail'
import Bonus from '@/pages/Bonus'
import AdventureMap from '@/pages/AdventureMap'

Vue.use(Router)

export default new Router({
    mode: 'history',
    hashbang: false,
    routes: [
        {
            path: '/',
            name: 'Home',
            component: Home,
        },
        {
            path: '/lab-:id',
            component: Detail,
            props: true
        },
        {
            path: '/policy',
            name: 'Policy',
            component: Policy
        },
        {
            path: '/intro',
            name: 'Introduction',
            component: Introduction
        },
        {
            path: '/info',
            name: 'Information',
            component: Information
        },
        {
            path: '/bonus',
            name: 'Bonus',
            component: Bonus
        },
        {
            path: '/map',
            name: 'Map',
            component: AdventureMap
        },
        {
            path: '**',
            name: 'PageNotFound',
            component: Home
        }
    ]
})