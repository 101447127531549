import Vue from 'vue'
import Router from 'vue-router'

import Introduction from '@/pages/Introduction'
import Policy from '@/pages/Policy'

Vue.use(Router)

export default new Router({
    mode: 'history',
    hashbang: false,
    routes: [
        {
            path: '/',
            name: 'Introduction',
            component: Introduction
        },
        {
            path: '/policy',
            name: 'Policy',
            component: Policy
        },
        {
            path: '**',
            name: 'PageNotFound',
            component: Introduction
        }
    ]
})