<template>
    <div>
        <router-view/>

        <a class="button" href="/" onclick="document.cookie='templi_visited=1';if(typeof localStorage!=='undefined'){localStorage.setItem('templi_visited','1')}">Pustime sa do toho</a>
        <p class="disclaimer">Pokračovaním súhlasíš z <a href="/policy">Podmienkami používania</a> týchto stránok.</p>
    </div>
</template>

<style scoped>

    a.button {
        display: block;
        text-decoration: none;
        background: rgba(255, 255, 255, .80);
        border-radius: 3px;
        border: 2px #B3262A solid;
        color: #B3262A;
        font-weight: bold;
        font-family: sans-serif;
        text-transform: uppercase;
        width:75%;
        max-width: 250px;
        margin: 10px auto;
        padding: 15px;
        cursor: default;
    }

    p.disclaimer {
        text-align: center;
        font-size:80%;
    }

    p.disclaimer a {
        color: #B3262A;
    }

    div {
        margin: 0 auto;
        padding: 20px;
    }

    main {
        max-width: 960px;
        margin: 0 auto;
    }

</style>

<script>
    export default {

    }
</script>