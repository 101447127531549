<template>

        <div class="container">
            <div class='menu'>
                <a href="/">
                    <div class="logo"></div>
                    <h1>SIGILLVM × MILITVM × TEMPLI</h1>
                </a>
                <ul >
                    <li v-for="(link, index) in links" :key="index">
                        <router-link :to="link.to"><img :src="link.icon" :alt="link.name" :title="link.name" ><span>{{ link.name }}</span></router-link>
                    </li>
                </ul>
            </div>

            <router-view/>

        </div>


</template>

<style scoped>

.container {
    width: 100%;
    position: absolute;
}

.menu {
    position: fixed;
    bottom: 0;
    z-index:999;
    width: 100%;
    background: white;
    color: darkgray;
    text-align:center;
    border-top: 1px solid rgba(0,0,0,.27);

}

.menu a {
    text-decoration: none;
    color: #333333;
}

.menu ul {
    margin: 0;
    padding: 0;
}
.menu li {
   list-style-type: none;
    display: inline-block;
    width: 20%;
    margin:10px 5px;
}

.menu li a {
    text-decoration: none;
    font-family: sans-serif;
    font-size: 0.7em;
}
.menu li a img {
    filter: grayscale(100%) opacity(50%);
    max-width: 24px;
    max-height: 24px;
}

.menu li a span {
    display: block;
    color: darkgray;
    text-decoration: none;

}

.menu li a.router-link-exact-active span {
    color: #B3262A;
}
.menu li a.router-link-exact-active img {
    filter: grayscale(0%) opacity(100%);
    max-width: 32px;
    max-height: 32px;
}


    h1 {
        display: none;

    }

@media (min-width: 1024px) and (orientation: landscape) {

    .logo {
        background: url("/assets/logo.png") no-repeat;
        width: 32px;
        height: 32px;
        display: inline-block;
        float:left;
        background-size: cover;
        margin: 8px 0 0 20px;
    }

    h1 {
        display: inline-block;
        float:left;
        margin: 0 0 0 8px;
        padding: 0;
        font-size: 1.5em;
        top: 8px;
        position: relative;
        font-family: Fondamento;
    }

    .menu {
        top: 0;
        bottom: auto;
        border-bottom:  1px solid rgba(0,0,0,.27);
        text-align: right;
        height: 50px;
    }

    .menu li {
        width: auto;
        margin-right: 50px;


    }

    .menu li a {
        font-size: 1em;
    }

    .menu li a span {
        padding-left: 8px;
        display: inline-block;
        top: -6px;
        position: relative;
        font-family: Fondamento;
    }

    .menu li a.router-link-exact-active img {
        max-width: 24px;
        max-height: 24px;
    }


}


</style>

<style global>

    .maxwidth {
        max-width: 960px;
        margin: 0 auto;
    }

    main {
        padding: 20px 20px 80px 20px;
    }

    @font-face {
        font-family: 'Fondamento';
        src:  url('/fonts/fondamento/Fondamento.ttf.woff') format('woff'),
        url('/fonts/fondamento/Fondamento.ttf.eot'),
        url('/fonts/fondamento/Fondamento.eot?#iefix') format('embedded-opentype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'PT Serif';
        src: url('/fonts/pt-serif/PTSerif-Italic.eot');
        src: url('/fonts/pt-serif/PTSerif-Italic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/pt-serif/PTSerif-Italic.woff2') format('woff2'),
        url('/fonts/pt-serif/PTSerif-Italic.woff') format('woff');
        font-weight: normal;
        font-style: italic;
    }

    @font-face {
        font-family: 'PT Serif';
        src: url('/fonts/pt-serif/PTSerif-Regular.eot');
        src: url('/fonts/pt-serif/PTSerif-Regular.eot?#iefix') format('embedded-opentype'),
        url('/fonts/pt-serif/PTSerif-Regular.woff2') format('woff2'),
        url('/fonts/pt-serif/PTSerif-Regular.woff') format('woff');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'PT Serif Caption';
        src: url('/fonts/pt-serif/PTSerif-Caption.eot');
        src: url('/fonts/pt-serif/PTSerif-Caption.eot?#iefix') format('embedded-opentype'),
        url('/fonts/pt-serif/PTSerif-Caption.woff2') format('woff2'),
        url('/fonts/pt-serif/PTSerif-Caption.woff') format('woff');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'PT Serif';
        src: url('/fonts/pt-serif/PTSerif-Bold.eot');
        src: url('/fonts/pt-serif/PTSerif-Bold.eot?#iefix') format('embedded-opentype'),
        url('/fonts/pt-serif/PTSerif-Bold.woff2') format('woff2'),
        url('/fonts/pt-serif/PTSerif-Bold.woff') format('woff');
        font-weight: bold;
        font-style: normal;
    }

    @font-face {
        font-family: 'PT Serif Caption';
        src: url('/fonts/pt-serif/PTSerif-CaptionItalic.eot');
        src: url('/fonts/pt-serif/PTSerif-CaptionItalic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/pt-serif/PTSerif-CaptionItalic.woff2') format('woff2'),
        url('/fonts/pt-serif/PTSerif-CaptionItalic.woff') format('woff');
        font-weight: normal;
        font-style: italic;
    }

    @font-face {
        font-family: 'PT Serif';
        src: url('/fonts/pt-serif/PTSerif-BoldItalic.eot');
        src: url('/fonts/pt-serif/PTSerif-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/pt-serif/PTSerif-BoldItalic.woff2') format('woff2'),
        url('/fonts/pt-serif/PTSerif-BoldItalic.woff') format('woff');
        font-weight: bold;
        font-style: italic;
    }

    html,
    body {
        margin: 0;
        padding: 0;
        text-align:center;
        width: 100%;
        font-family: 'PT Serif';
    }

    body:before {
        content: "";
        display: block;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -10;
        background: url(/assets/bg_light.jpg) no-repeat center center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }

    *
    {
        -webkit-user-select: none;
        -moz-user-select: -moz-none;
        /*IE10*/
        -ms-user-select: none;
        user-select: none;

        /*You just need this if you are only concerned with android and not desktop browsers.*/
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    @media (min-width: 1024px) and (orientation: landscape) {
        main {
            padding: 80px 20px 0px 20px;

        }
    }

</style>

<script>
    export default {
        data: () => ({
            links: [
                {
                    name: 'Informácie',
                    to: '/info',
                    icon: '/assets/help.png'
                },
                {
                    name: 'Adventúry',
                    to: '/',
                    icon: '/assets/mountain-peak.png'
                },
                {
                    name: 'Mapa',
                    to: '/map',
                    icon: '/assets/pin.svg'
                },
                {
                    name: 'Bonus',
                    to: '/bonus',
                    icon: '/assets/chest.png'
                },



            ]
        })
    }


</script>