import Vue from 'vue'
import App from './App.vue'
import Intro from './Intro.vue'
import router from './router'
import routerintro from './routerintro'

Vue.config.productionTip = false

function getVisited(name) {
    let visited = false;
    if (document.cookie.indexOf(name+'=')!= -1) visited = true;
    if (typeof localStorage !== 'undefined') {
        if (localStorage.getItem(name) != null) visited = true;
    }
    return visited;
}

if (getVisited("templi_visited")) {
    // already visited site
    new Vue({
        el: '#app',
        router,
        components: {App},
        template: '<App/>'
    })
} else {
    // show introduction
    new Vue ({
        el: '#app',
        router: routerintro,
        components: {Intro},
        template: '<Intro/>'
    })
}


    if('serviceWorker' in navigator) {
        navigator.serviceWorker.register('/service-worker-cache.js');
    }