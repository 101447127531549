<template>
    <main class="maxwidth">
        <p v-if="visible" v-html="bonusinfo"></p>
        <div class="entryPanel" v-if="visible==false">
            <h2 class="fond templarred">Bonusová keška</h2>
            <p class="justified">Pre získanie bonusového pokladu musíš zadať heslá od jednotlivých majstrov. Zadávaj ich rovnako, ako do aplikácie Adventure Lab. Pokiaľ máš heslá zapísané v nejakej poznámkovej aplikácii, nekopíruj ich, ale prepíš.</p><p class="justified">Ak bude tvoje riešenie správne, získaš súradnice a pokyny ku keške <a href="https://coord.info/GC869XC" target="_blank">GC869XC</a>.</p>
            <form id="bonusform" name="bonusform">
                <div><label for="input1">Antiquis Libri</label><input id="input1" name="input1"></div>
                <div><label for="input2">De Profundis</label><input id="input2" name="input2"></div>
                <div><label for="input3">Ecce lignum crucis</label><input id="input3" name="input3"></div>
                <div><label for="input4">Et legatum ex mortuis magister</label><input id="input4" name="input4"></div>
                <div><label for="input5">In pivo veritas</label><input id="input5" name="input5"></div>
                <div><label for="input6">Jacques de Molay</label><input id="input6" name="input6"></div>
                <div><label for="input7">Ohittey ándelsop apots</label><input id="input7" name="input7"></div>
                <div><label for="input8">Poeta</label><input id="input8" name="input8"></div>
                <div><label for="input9">Setina</label><input id="input9" name="input9"></div>
                <div><label for="input10">Terra Eburhardi</label><input id="input10" name="input10"></div>

                <a v-on:click="convertFormFields" class="button marginal">Overiť riešenie</a>
            </form>
        </div>
    </main>
</template>

<script>

    import Custom from "../custom"

    export default {
        name: "Bonus",
        data() {
            return {
                bonusinfo: "&nbsp;",
                visible: false
            }
        },
        methods: {
            convertFormFields() {
                var formElements = document.getElementById("bonusform").elements;
                var formstring = "";
                for (var formIndex = 0; formIndex < formElements.length; formIndex++) {
                    formstring += formElements[formIndex].value;
                }
                formstring = this.cleanText(formstring);
                //console.log(formstring);
                if (formstring!="")
                    this.getSettings(formstring);

            },
            cleanText(input) {
                var dia = "áäčďéíľĺňóôŕšťúýÁČĎÉÍĽĹŇÓŠŤÚÝŽ";
                var nodia = "aacdeillnoorstuyACDEILLNOSTUYZ";
                var convertText = "";
                for(var i=0; i<input.length; i++) {
                    if(dia.indexOf(input.charAt(i))!=-1) {
                        convertText += nodia.charAt(dia.indexOf(input.charAt(i)));
                    }
                    else {
                        convertText += input.charAt(i);
                    }
                }
                input = convertText;
              input = input.replace(/\s/g, '');
              input = input.toLowerCase();
              return input;
            },
            getSettings(field) {
                window.cjs = require("crypto-js");
                try {
                    var bytes = window.cjs.AES.decrypt(Custom.settings.toString(), field);
                    var decryptedData = JSON.parse(bytes.toString(window.cjs.enc.Utf8));
                    if (decryptedData.valid) {
                        this.visible = true;
                        this.bonusinfo = decryptedData.content;
                    } else {
                        alert("Nepodarilo sa overiť platnosť zadaných hesiel.\nSkús ich zadať znovu.");
                    }
                }
                catch (e) {
                    alert("Nepodarilo sa overiť platnosť zadaných hesiel.\nSkús ich zadať znovu.");
                }
            }
        }
    }

</script>

<style global>
    .fond {
        font-family: Fondamento, serif;
    }

    .templarred {
        color: #B3262A;
    }

    .imgfit {
        width: 100%;
    }

    .justified {
        text-align: justify;
    }
</style>

<style scoped>
    label {
        font-family: Fondamento, serif;
        color: #B3262A;
        display: block;
    }

    input {
        display: block;
        margin-bottom: 16px;
        text-align: center;
        width: 100%;
        font-size: 120%;
        font-family: "Courier New", monospace;
        font-weight: bold;
        padding: 6px 0;
    }

    a.button {
        text-align: center;
        text-decoration: none;
        color: #B3262A;
        background: white;
        border: 2px solid #B3262A;
        padding: 10px;
        border-radius: 5px;
        margin: 50px auto;
        font-family: Fondamento,serif;
        font-weight: bold;
        font-size:1.2em;
        display: block;
        width: fit-content;
    }

    @media (min-width: 1024px) and (orientation: landscape) {
        form div {
            display: inline-block;
            width: 45%;
            margin: 1%;
        }
    }

</style>