<template>
    <main class="maxwidth">
        <div>
            <div class="lang" v-if="adventure.description_en != null">
                <a @click="language = 'sk'"><img src="/assets/slovakia.svg" v-if="language=='sk'" class="selected" alt="Slovenská vlajka" title="Slovensky"></a>
                <a @click="language = 'sk'"><img src="/assets/slovakia.svg" v-if="language=='en'" alt="Slovenská vlajka" title="Slovensky"></a>
                <a @click="language = 'en'"><img src="/assets/uk.svg" v-if="language=='en'" class="selected" alt="British flag" title="English"></a>
                <a @click="language = 'en'"><img src="/assets/uk.svg" v-if="language=='sk'" alt="British flag" title="English"></a>
            </div>
            <h2>{{adventure.name}}</h2>
            <div v-if="language== 'sk'" class="description" v-html="adventure.description"></div>
            <div v-if="language== 'en'" class="description" v-html="adventure.description_en"></div>
            <div class="description infopanel">
                <img src="/assets/puzzle.svg" alt="D" title="D"> {{adventure.difficulty}}
                <img src="/assets/mountain.svg" alt="T" title="T"> {{adventure.terrain}}
                <img src="/assets/clock.svg" alt="Time" title="Čas"> {{adventure.time}}
                <div v-if="language== 'sk'" class="owner">Toto dobrodružstvo pre teba pripravil<span v-if="adventure.owner_plural">i</span> {{adventure.owner}}.</div>
                <div v-if="language== 'en'" class="owner">This Adventure was created for you by {{adventure.owner}}.</div>
            </div>
        </div>
    </main>
</template>

<script>
    import Adventures from '../adventures'


    export default {

        name: "Detail",
        props: ['id'],

        data: function(){
            return {
                language: 'sk'
                }

        },

        computed: {
            adventure () {
                return Adventures.filter(adventure => adventure.id == this.id)[0];
            }
        },
    }
</script>

<style global>
    .description img, .description img.full {
        max-width: 560px;
        width: 100%;
    }

    .description a.action {
        text-align: center;
        text-decoration: none;
        color: #B3262A;
        background: white;
        border: 2px solid #B3262A;
        padding: 10px;
        border-radius: 5px;
        margin: 50px;
        font-family: Fondamento,serif;
        font-weight: bold;
        font-size:1.2em;
    }

    .description div.fancy {
        font-family: Fondamento, serif;
        font-weight: bold;
    }

    .description div.slim {
        width: fit-content;
        margin: 0 auto;
    }

    .description div.fancy h4 {
        text-align: center;
        color: #4D0B01;
        font-size:130%;
    }

    .showmobile {
        display: inherit!important;
    }

    .showdesktop {
        display: none!important;
    }


    @media (min-width: 1024px) and (orientation: landscape) {
        .description img.half {
            max-width: 280px;
            width: 50%;
        }

        .description img.left {
            float: left;
            margin-right: 24px;
            margin-bottom: 24px;
        }

        .description img.right {
            float: right;
            margin-left: 24px;
            margin-bottom: 24px;
        }

        .showmobile {
            display: none!important;
        }

        .showdesktop {
            display: inherit!important;
        }

    }
</style>

<style scoped>
    h2 {
        font-family: Fondamento;
        color: #B3262A;
        font-size:180%;
    }

    .description {
        max-width: 560px;
        text-align: justify;
        line-height: 170%;
        margin: 0 auto ;
    }

    .lang {
        display: block;
        width: fit-content;
        float: right;
        position: absolute;
        top: 0px;
        right: 20px;
    }

    .lang a {
        display: inline-block;
    }
    .lang img {
        vertical-align: top;
        width: 24px;
    }
    .lang img.selected {
        width: 30px;
    }

    .owner {
        margin-top: 24px;
        color: #333333;
        font-size: 80%;

    }

    .infopanel {
        padding-top:24px;
        width: 100%;
        border-top: 1px solid darkgray;
        //font-family: Fondamento, serif;
        font-size: 1.1em;
        text-align: center;
    }
    .infopanel img {
        width: 12px;
        margin-left: 16px;
    }

    .infopanel img:first-child {
        margin: 0;
    }

    @media (min-width: 1024px) and (orientation: landscape) {
        .lang {
            top: inherit;
        }

        .lang a {
            display: block;
        }

        .owner {
            margin-bottom: 32px;
        }
    }
</style>