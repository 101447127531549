<template>
    <a :href="'/lab-' + adventure.id">
        <div class="card" :style="'background:url(/assets/lab-'+ adventure.id + '.jpg) no-repeat;background-size:cover;'" >
            <div class="cardcontent">
                <h3>{{adventure.name}}</h3>
                <span class="location"><img src="/assets/placeholder.svg"> {{adventure.location}}</span> <span class="owner"><img src="/assets/user.svg"> {{adventure.owner}}</span>
            </div>
        </div>
    </a>
</template>

<script>
    export default {
        name: "AdventureCard",
        props: ['adventure']
    }
</script>

<style scoped>
    a {
        text-decoration: none;
        color: black;
    }

    .card {
        display: block;
        border: 1px solid lightgrey;
        background: white;
        border-radius: 5px 5px 5px 5px;
        margin-bottom: 8px;
        background-size: contain;
        min-height: 100px;
        background-position: 50% 50%;


    }

    .cardcontent {
        background: white;
        border-radius: 0 0 5px 5px;
        border-color: white;
        padding: 16px;
        margin-top: 100px;

    }

    h3 {
        margin: 0;
        padding: 0;
        font-family: Fondamento;
        color: #B3262A;
        font-size:140%;
    }

    .location img, .owner img {
        width:14px;
        height:14px;
    }
    .owner {
        margin-left: 24px;
    }

    @media (min-width: 1024px) and (orientation: landscape) {
        .card {
            width: 45%;
            display: inline-block;
            margin: 20px;
            filter: grayscale(100%);
        }

        a:hover .card {
            filter: grayscale(0%);
        }
    }
</style>