<template>
    <main>
        <div id="map"></div>
    </main>
</template>

<script>


    import L from '../../static/leaflet/leaflet.js';
    import Adventures from '../adventures'
    import AdventureCard from '../components/AdventureCard'

    export default {
        name: "AdventureMap",
        data: {
            map: null,
            tileLayer: null,
            layers: [],
        },
        mounted() {

            // inject css
            let leafletStyle = document.createElement('link');
            leafletStyle.setAttribute('href', '/assets/leaflet.css');
            leafletStyle.setAttribute('rel', 'stylesheet');
            document.head.appendChild(leafletStyle);

            this.initMap();
            this.initLayers();

        },
        methods: {
            initMap() {
                var mapOptions = {
                    doubleClickZoom: true,
                    attributionControl: false
                };

                this.map = L.map('map', mapOptions).setView([48.9, 19.4], 7);

                if(window.innerHeight < window.innerWidth) {
                    this.map.zoomControl.setPosition('bottomright');
                } else {
                    //L.control.attribution({position: 'rop'})
                }

                this.tileLayer = L.tileLayer(
                    '//stamen-tiles-{s}.a.ssl.fastly.net/watercolor/{z}/{x}/{y}.jpg',
                    {
                        maxZoom: 18,
                        attribution: 'Map tiles by <a href="http://stamen.com">Stamen Design</a>, under <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a>. Data by <a href="http://openstreetmap.org">OpenStreetMap</a>, under <a href="http://creativecommons.org/licenses/by-sa/3.0">CC BY SA</a>.',
                    }
                );
                this.tileLayer.addTo(this.map);
            },
            initLayers() {
                var grp = L.featureGroup();
                for (var i in Adventures) {
                    var marker = L.marker([Adventures[i].lat, Adventures[i].lon], {
                        icon: L.icon({
                            iconUrl: '/assets/shieldbevel.png',
                            iconSize: [40, 40]
                        }),
                        title: Adventures[i].name,
                        alt: i
                    }).bindPopup(function (popup) {
                            var adventure = Adventures[popup._icon.alt];
                            var c = "<div class='popupContent'><h3>"+adventure.name+"</h3>";
                            c = c + "<span class=\"owner\"><img src=\"/assets/user.svg\"> "+ adventure.owner +"</span>";

                            var navigateCoords = adventure.lat+","+adventure.lon;
                            if (adventure.parking !== null) {
                                if (typeof adventure.parking  !== 'undefined') {
                                    navigateCoords = adventure.parking;
                                }
                            }

                            c = c + "<div class='buttons'><a href=\"/lab-"+ adventure.id +"\">Otvoriť detail</a> <a href=\"https://maps.google.com/?daddr="+navigateCoords+"\" target=\"_blank\">Navigácia</a></div>";
                            c = c + "</div>";
                            return c;
                        });
                    marker.addTo(grp);
                    marker.addTo(this.map);
                }


                //console.log(map.markers);
                this.map.fitBounds(grp.getBounds());
                this.map.panTo(grp.getBounds().getCenter());

            },
            showAdventure(i) {
                console.log(i);
            }
        },
    }
</script>

<style scoped>
    main {
        padding: 0;
        margin: 0;
    }
    #map {
        width: 100%;
        height: 100vh;
    }

</style>

<style global>
.leaflet-tile {
    filter: grayscale(75%)!important;
}

.popupContent {
    font-family: "PT Serif", serif;
    font-size:110%;
    text-align: center;
}

.popupContent h3 {
        margin: 24px 0 10px 0;
        padding: 0;
        font-family: Fondamento;
        color: #B3262A;
        font-size:140%;
    }

.popupContent .owner img {
        width:14px;
        height:14px;
    }

.popupContent .button {
    margin: 24px;
}
.popupContent a {
    text-decoration: none;
    color: #B3262A!important;
    font-family: Fondamento, serif;
    font-weight: bold;
    font-size:110%;
    display: block;
    text-align: center;
    width: auto;
    margin: 10px;
    padding: 5px;
    border: 2px solid #B3262A;
    border-radius: 5px;
}

.popupContent a:hover {
    background: #f4f4f4;
}

</style>