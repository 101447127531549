<template>
    <main class="maxwidth">
        <div>
            <adventure-card  v-for="adventure in adventures" :adventure="adventure" :key="adventure.id">
            </adventure-card>
        </div>
    </main>
</template>

<style scoped>

</style>

<script>
    import AdventureCard from "../components/AdventureCard";
    import Adventures from '../adventures'

    export default {
        components: {AdventureCard},
        data() {
            return {
                adventures: Adventures.sort(function(a,b) {var x = a["name"]; var y = b["name"]; return ((x < y) ? -1 : ((x > y) ? 1 : 0)); })
            }
        }
    }


</script>